const headerFilterButtons = document.querySelectorAll('.header__button--filter');

// Rofitlich herausschieben
const headerLogoRofitlichDiv = document.getElementById('header__logo-rofitlich');
const headerLogoDiv = document.getElementById('header__logo');
const headerDiv = document.getElementById('header');
// Auf der Startseite wird der header per Scrolltrigger fixiert,
// deshalb dürfen der header und Elemente darin nicht Trigger sein
// für die rofitlich-Animation, stattdessen ist es der Abschnitt komplette
const headerLogoRofitlichTrigger = document.body.getAttribute('data-seitenname') == 'startseite' ? document.getElementById('komplette') : headerDiv;

let headerLogo = gsap.timeline({
    scrollTrigger: {
        id: 'headerLogoRofitlich',
        trigger: headerLogoRofitlichTrigger,
        start: () => `${parseInt(window.getComputedStyle(headerDiv).paddingTop) + (headerLogoDiv.offsetHeight * 1)} top`,
        end: () => `${(parseInt(window.getComputedStyle(headerDiv).paddingTop) * 2) + (headerLogoDiv.offsetHeight * 2)} top`,
        scrub: true,
        //markers: true
        invalidatenRefresh: true,
    }
})
    .to(headerLogoRofitlichDiv, {
        immediateRender: true,
        ease: 'none',
        y: function(index, target, targets) {
            let headerPadding = parseInt(window.getComputedStyle(headerDiv).paddingTop);
            return (headerPadding + headerLogoDiv.offsetHeight) * -1;
        },
    }, 0)


// Filter ausfaden
const filterFadeTrigger = document.querySelector('[data-filter-fade-trigger]');
const headerZeile2Div = document.getElementById('header__zeile--2');
gsap.timeline({
    scrollTrigger: {
        id: 'headerFilterBlur',
        trigger: filterFadeTrigger,
        start: `top+=100 top`,
        end: `top+=200 top`,
        scrub: true,
        // markers: true,
        onLeave: () => toggleMenuePointerEvents(false),
        onEnterBack: () => toggleMenuePointerEvents(true)
    }
})
    .to(headerZeile2Div, {
        immediateRender: true,
        ease: 'none',
        filter: 'blur(5px)',
    }, 0)

gsap.timeline({
    scrollTrigger: {
        id: 'headerFilterOpacity',
        trigger: filterFadeTrigger,
        start: self => self.previous().end,
        end: () => self => self.previous().end + 50,
        scrub: true,
        // markers: true
    }
})
    .to(headerZeile2Div, {
        immediateRender: true,
        ease: 'none',
        opacity: 0,
    }, 0)

// Filter einblenden
const menueFilterKnopf = document.getElementById('header__button--filter-knopf');
if (menueFilterKnopf !== null) {
    menueFilterKnopf.addEventListener('click', function () {
        let status = document.body.getAttribute('data-filter-aktiv') === 'true'
            ? false
            : true;
        // Wenn die Filter ohnehin sichtbar sind, soll der Filterknopf nur deaktivieren, aber nicht aktivieren.
        // Er reagiert in diesem Fall auf keinen Klick.
        if (status === false || (status === true && ScrollTrigger.getById('headerFilterBlur').progress == 1)) {
            document.body.setAttribute('data-filter-aktiv', status);
            this.setAttribute('data-aktiv', status);
            toggleMenuePointerEvents(status);
        }
    });
}

const headerButtonFilter = document.querySelectorAll('.header__button--filter');
function toggleMenuePointerEvents(boolean) {
    for (i = 0; i < headerButtonFilter.length; i++) {
        headerButtonFilter[i].setAttribute('data-pointer-events', boolean);
    }
}