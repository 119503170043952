const cursorInner = document.querySelectorAll('.cursor__inner');
// cursorPositionWindow zeigt an, wo der cursor ist:
// true für im window (standard)
// false, wenn Cursor in einem beliebigen Unterelement ist (das ein transform hat),
// zum Beispiel projektThemenCursorVerschieben
var cursorPositionWindow = true;
let cursorInnerSymbol = document.querySelector('.cursor__inner--symbol');
let cursorInnerBlur = document.querySelector('.cursor__inner--blur');
var cursorTextInterval;
let cursorTextArray = [];

/**
 * Cursor div per GSAP bewegen
 */
gsap.set('.cursor', {
    xPercent: -50,
    yPercent: -50
}
);

let cursorXTo = gsap.quickTo('.cursor', 'x', {
    duration: 0.6,
    ease: 'power3'
}),
    cursorYTo = gsap.quickTo('.cursor', 'y', {
        duration: 0.6,
        ease: 'power3'
    });

window.addEventListener('mousemove', event => {
    if (cursorPositionWindow === true) {
        cursorXTo(event.clientX);
        cursorYTo(event.clientY);
    } else {
        let bounds = document.querySelector('.cursor').closest(cursorPositionWindow).getBoundingClientRect();
        cursorXTo(event.clientX - bounds.left);
        cursorYTo(event.clientY - bounds.top);
    }
});

/**
 * Touch und Maus erkennen
 */
window.addEventListener('pointermove', function (event) {
    if (event.pointerType === 'touch') {
        document.body.dataset.cursorTouch = "touch";
    } else {
        document.body.dataset.cursorTouch = "cursor";
    }
})

/**
 * 
 */
document.addEventListener('mouseover', function (event) {
    if (event.target.closest('[data-cursor]')) {
        let cursor = event.target.closest('[data-cursor]').dataset.cursor;
        let faktor = event.target.closest('[data-cursor]').dataset.cursorFaktor;
        document.body.dataset.cursorStatus = 'true';
        document.body.dataset.bodyCursor = cursor;
        // Wenn der Cursor vergrössert werden soll
        if (faktor > 1) {
            cursorInner.forEach(function (inner, index) {
                inner.style.scale = faktor;
            });
        }
        // Wenn ein Text angezeigt werden soll
        if (event.target.closest('[data-cursor]').dataset.cursorText) {
            cursorTextArray = event.target.closest('[data-cursor]').dataset.cursorText.split(',');
            if (cursorTextArray.length > 0) {
                startCursorText(cursorTextArray);
            }
        }
    }
    else {
        document.body.dataset.cursorStatus = 'false';
        document.body.dataset.bodyCursor = 'false';
        document.body.dataset.bodyCursorFaktor = 'false';
        cursorInner.forEach(function (inner, index) {
            inner.style.scale = '';
        });
        clearInterval(cursorTextInterval);
        cursorTextArray.length = 0;
        cursorInnerSymbol.setAttribute('data-text', '');
        cursorInnerBlur.setAttribute('data-text', '');
    }
});

function startCursorText(cursorTextArray) {
    // https://codepen.io/benfrain/pen/rRxpKq
    let i = 0;
    // Zuerst die Funktion direkt ausführen, denn das interval beginnt erst nach der angegebenen Zeit
    function cursorTextTauschen() {
        i = (i + 1) % cursorTextArray.length;
        cursorInnerSymbol.setAttribute('data-text', cursorTextArray[i]);
        cursorInnerBlur.setAttribute('data-text', cursorTextArray[i]);
    }
    cursorTextTauschen();
    cursorTextInterval = setInterval(cursorTextTauschen, 700);
}